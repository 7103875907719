
import {navigationIsOpen, defaultHeaderStatus} from '/src/lib/stateStore.ts';

const linkShorts : any = {
  "[contact]": "/about/contact"
}

export const calcBreadcrumbs = (navigationMap: { [key: string]: any }, url: string, langShort: string) => {
  
    //console.log("calcBreadcrumbs", {navigationMap, url, langShort});
    if(!navigationMap) return [];
    const parts = url.split('/').filter(part => part !== 'http:' && part !== ''); // Filter out 'http:' and empty strings
    const result = [];

    for (let i = 0; i < parts.length; i++) {
      const path = parts.slice(0, i + 1).join('/');
      
      if(navigationMap["/" + path]?.title) {
        //console.log("calcBreadcrumbs #2", {seg: path, data: navigationMap["/" + path]});
        result.push({ ["/" + path]: navigationMap["/" + path]?.title });
      }
    }

    return result;
}

export const setHeaderStyle = (status : string) => {
  //console.log("setHeaderStyles", {status, defaultHeaderStatus: defaultHeaderStatus.get(), navigationIsOpen: navigationIsOpen.get()});
  
  if(!navigationIsOpen.get()) {
    document.body.classList.remove("status-header-opaque", "status-header-transparent", "status-header-navigation");
    document.body.classList.add(`status-header-${status}`);
  } 
  if(status !== 'navigation') defaultHeaderStatus.set(status);
}

export const getLink = (link : string) => {
  //console.log("getLink");
  
  if(link.startsWith('http')) return link;
  if(linkShorts[link]) return linkShorts[link];
  return link;

}

export const getNavigationInfoForUrl = (navigation:any, url:string, language : string) => {
  //console.log("getNavigationInfoForUrl", {navigation, url, language});

  //const path = url.replace('/' + language, '');
  return navigation[url] || navigation[url + "/"];
  


}
