const getEnv = (key: string): any => {
    if (typeof window !== 'undefined') {
      // Browser environment
      return (import.meta.env as any)[key];
    } else {
      // Server environment
      return process.env[key] || (import.meta.env as any)?.[key];
      
    }
  };
  
  const parseBoolean = (value: any): boolean => {
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    }
    return Boolean(value);
  };


  
  const config = {
    SMART_RECRUITERS_API_URL: "https://api.smartrecruiters.com/v1/companies/ATParchitekteningenieure/postings",
    DIRECTUS_URL: getEnv('DIRECTUS_URL') || 'https://atp.directus.app/',
    USE_CACHE: parseBoolean(getEnv('USE_CACHE')),
    STORE_TO_CACHE: parseBoolean(getEnv('STORE_TO_CACHE')),
    IS_DEV: parseBoolean(getEnv('DEV')),
    IS_PROD: parseBoolean(getEnv('PROD')),
    DID_UPDATE: !(parseBoolean(getEnv('PROD')) && parseBoolean(getEnv('USE_CACHE'))),
  };
  console.log("CREATE CONFIG", {config});
  
  export default config;