export const createHash = (str: string) => {
  return str.split('').reduce((acc, char) => {
    return acc + char.charCodeAt(0);
  }, 0);
}

export const generateSlug = (title: string) => {

  return !title ? "" : (
    title
      // remove leading & trailing whitespace
      .trim()
      // remove special characters
      .replace(/[^A-Za-z0-9\- ]/g, '')
      // replace spaces
      .replace(/\s+/g, '-')
      // remove leading & trailing separtors
      .replace(/^-+|-+$/g, '')
      // output lowercase
      .toLowerCase()
  )
}


// export const createDateFromGermainString = (dateString) => {
//   const [day, month, year] = dateString.split('-');
//   return new Date(year, month - 1, day);
// }
// export const extractGermainDate = (str) => {
//   const dateRegex = /\b\d{2}\.\d{2}\.\d{4}\b/;
//   const match = str.match(dateRegex);
//   return match ? match[0] : null;
// }

export const replaceLiterals = (template : string, placeholders : any) => {
  if (typeof template !== 'string') {
    return template;   }

  return template.replace(/\${(.*?)}/g, (_, key) => placeholders[key] || '');
}

// set property in nested object structure
export const setProp = (obj : any, path : string, value : any) => {
  let keys = path.split('.');
  let lastKey = keys.pop();
  let deep = obj || {};

  for (let key of keys) {
      if (!deep[key]) {
          deep[key] = {};
      }
      deep = deep[key];
  }

  if(lastKey) deep[lastKey] = value;
  return obj;
}

export const isTouchDevice = () => {
  return ('ontouchstart' in window) || 
         (navigator.maxTouchPoints > 0) || 
         (navigator.msMaxTouchPoints > 0) || 
         (window.matchMedia && window.matchMedia("(pointer: coarse)").matches);
}



export const debounce = (func : Function, wait : number) => {
  let timeout : any;
  return function(...args : any) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export const helloWorld = () => {
  console.info('************ Hello World ************');
}


export const scrollToId = (elementId : string, offset : number = 0) => {
  const contentEl = document.getElementById(elementId);
  if (contentEl) {
    var elementPosition = contentEl.getBoundingClientRect().top + window.pageYOffset;
    var offsetPosition = elementPosition + offset;
    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
  } 
}

export const transformEmail = (email : string) => {
  // Split the email address into two parts: the local part and the domain
  const [localPart, domainPart] = email.split('@');
  
  // Replace '@' with '[at]' and '.' with '[dot]' in the domain part
  const transformedDomain = domainPart.replace(/\./g, '[dot]');
  
  // Concatenate 'info_' to the local part and join with the transformed domain part
  const transformedEmail = `${localPart}[at]${transformedDomain}`;
  
  return transformedEmail;
}

const getOptionalValueOrDefault = (allValues : any, searchedValues : any, defaultValue : string) => {
  //console.log("getOptionalValueOrDefault", {allValues, searchedValues, defaultValue});
  const foundValue = searchedValues?.find((value : any) => allValues?.includes(value));
  return foundValue ? foundValue : defaultValue;
}

export const getSectionClasses = (type : string, layoutOptions : any, inContainer = false, isContainer = false) => {
  
  let allClasses = ["relative", "transition-all"];
  if(!isContainer) allClasses.push(getOptionalValueOrDefault(layoutOptions, [".w-full", ".max-w-6xl.mx-auto"], ".w-layout-standard")?.replaceAll(".", " "));
  if(!isContainer) allClasses.push(getOptionalValueOrDefault(layoutOptions, [".px-0"], ".px-layout-standard")?.replaceAll(".", " "));
  if(isContainer) allClasses.push(getOptionalValueOrDefault(layoutOptions, [".space-y-layout-medium", ".space-y-layout-dense"], ".space-y-layout-standard")?.replaceAll(".", " "));
  if(!inContainer || isContainer) allClasses.push(getOptionalValueOrDefault(layoutOptions, [".py-layout-dense", ".pb-layout-standard", ".pt-layout-standard", ".py-0"], ".py-layout-standard")?.replaceAll(".", " "));
  if(isContainer) {
    if(layoutOptions?.includes(".bg-gray-50")) allClasses.push(" bg-gray-50 ");
    if(layoutOptions?.includes(".overflow-hidden")) allClasses.push(" overflow-hidden ");

  }
  //if(isContainer) console.log("getSectionClasses", {type, layoutOptions, inContainer, isContainer, allClasses});
  
  return allClasses.join(" ");
}

